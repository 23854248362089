<template>
  <el-autocomplete
    id="location-query"
    popper-class="advance-autocomplete"
    class="inline-input kkk"
    prefix-icon="el-icon-search"
    v-model="value.name"
    value-key="name"
    :fetch-suggestions="queryAutosuggest"
    :debounce="600"
    :trigger-on-focus="false"
    placeholder="The properites name. E.g: Hilton, Ibis or Swiss hotel"
    ref="autocomplete"
    :popper-options="{ boundariesElement: 'srcollParent' }"
    :popper-append-to-body="true"
    @select="handleAutosuggestSelect"
  >
    <i
      class="el-icon-close el-input__icon"
      slot="suffix"
      v-show="value.name"
      @click="handleClearClick">
    </i>
    <template slot-scope="{ item }">
      <div class="list is-gapless">
        <!-- <div class="list-middle">
          <i class="el-icon-location" />
        </div> -->
        <div class="list-content">
          <div class="list-value">{{ item.name }}</div>
          <div class="list-label">{{ item.location.city }}, {{ item.location.countryCode | country }}</div>
        </div>
      </div>
    </template>
  </el-autocomplete>
</template>

<script>
import { zumataAutosuggest } from '../factories/manage'

export default {
  name: 'PropertiesSearch',
  props: {
    value: {
      type: Object,
      required: true
    },
    appCode: {
      type: String,
      required: true,
      default: ''
    },
    types: {
      type: String,
      default: 'hotel_en_us'
    }
  },
  methods: {
    handleClearClick () {
      this.$emit('input', {
        regionId: undefined,
        locationQuery: ''
      })
      this.$refs.autocomplete.focus()
    },
    async queryAutosuggest (queryString, cb) {
      const blankResults = [{ name: 'Not found', location: { city: 'Try to search others name' } }]
      const trimed = queryString.trim()
      if (trimed === '') return cb(blankResults)
      try {
        const { results } = await zumataAutosuggest({
          q: trimed,
          types: this.types,
          includes: 'hotel_eager',
          locale: 'en-US'
        }, this.appCode)
        // this.autosuggestResults.region = outlets.results
        // this.autosuggestResults.address = data.address.predictions
        // this.autosuggestResults.airport = data.airport.results
        // this.autosuggestResults.trainStation = data.trainStation.results

        if (results.hotel_en_us == null || results.hotel_en_us.length === 0) return cb(blankResults)
        // const { city_en_us = empty } = data.results || {}
        return cb(results.hotel_en_us)
      } catch (err) {
        return cb(blankResults)
      }
    },
    handleAutosuggestSelect (val) {
      this.$refs.autocomplete.focus()
      this.$emit('input', { name: val.name })
      this.$emit('select', { name: val.name })
      this.$nextTick(() => {
        this.$emit('input', val)
        this.$emit('select', val)
      })
    }
  }
}
</script>

<style lang="scss">
  .el-autocomplete-suggestion li {
    padding: 0 6px !important;
  }
  .kkk {
    width: 100%;
  }
</style>
