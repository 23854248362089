import Router from 'vue-router'
import Vue from 'vue'
import store from '@/store'
import { Loading } from 'element-ui'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'is-active',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/users/login',
      meta: {},
      beforeEnter: (to, from, next) => {
        next('/')
      }
    },
    {
      path: '/orders',
      name: 'orders',
      props: (route) => ({
        query: route.query
      }),
      component: () => import(/* webpackChunkName: "order" */ './views/orders/List.vue'),
      meta: {},
      beforeEnter: (to, from, next) => {
        if (store.getters.usrToken) {
          return next()
        }
        next('/')
      },
      children: [
        {
          path: ':orderReference',
          name: 'orderDetails',
          props: (route) => ({
            orderReference: route.params.orderReference
          }),
          component: () => import(/* webpackChunkName: "order" */ './views/orders/ListDetails.vue'),
          meta: {},
          children: [
            // {

            // }
          ]
        }
      ]
    },
    {
      path: '/manage/applications',
      name: 'manageApplications',
      props: (route) => ({
        query: route.query
      }),
      component: () => import(/* webpackChunkName: "manage" */ './views/manage/Applications.vue'),
      meta: {},
      beforeEnter: (to, from, next) => {
        if (store.getters.usrToken) {
          return next()
        }
        next('/')
      }
    },
    {
      path: '/manage/applications/:appCode',
      name: 'manageApplicationsDetails',
      redirect: '/manage/applications/:appCode/overview',
      props: (route) => ({
        query: route.query,
        appCode: route.params.appCode
      }),
      component: () => import(/* webpackChunkName: "manage" */ './views/manage/ApplicationsDetails.vue'),
      meta: {},
      beforeEnter: (to, from, next) => {
        if (store.getters.usrToken) {
          return next()
        }
        next('/')
      },
      children: [
        {
          path: 'overview',
          name: 'manageApplicationsDetailsOverview',
          component: () => import(/* webpackChunkName: "manage" */ './views/manage/ApplicationsDetailsOverview.vue'),
          meta: {}
        },
        {
          path: 'preferences',
          name: 'manageApplicationsDetailsPreferences',
          component: () => import(/* webpackChunkName: "manage" */ './views/manage/ApplicationsDetailsPreferences.vue'),
          meta: {}
        },
        {
          path: 'credentials',
          name: 'manageApplicationsDetailsCredentials',
          component: () => import(/* webpackChunkName: "manage" */ './views/manage/ApplicationsDetailsCredentials.vue'),
          meta: {}
        },
        {
          path: 'payments',
          name: 'manageApplicationsDetailsPayments',
          component: () => import(/* webpackChunkName: "manage" */ './views/manage/ApplicationsDetailsPayments.vue'),
          meta: {}
        },
        {
          path: 'pricing',
          name: 'manageApplicationsDetailsPricing',
          component: () => import(/* webpackChunkName: "manage" */ './views/manage/ApplicationsDetailsPricing.vue'),
          meta: {}
        },
        {
          path: 'logs',
          name: 'manageApplicationsDetailsLogs',
          component: () => import(/* webpackChunkName: "manage" */ './views/manage/ApplicationsDetailsLogs.vue'),
          meta: {}
        },
        {
          path: 'dev',
          name: 'manageApplicationsDetailsDev',
          component: () => import(/* webpackChunkName: "manage" */ './views/manage/ApplicationsDetailsDev.vue'),
          meta: {}
        }
      ]
    },
    {
      path: '/manage/users',
      name: 'manageUsers',
      props: (route) => ({
        query: route.query
      }),
      component: () => import(/* webpackChunkName: "manage" */ './views/manage/Users.vue'),
      meta: {},
      beforeEnter: (to, from, next) => {
        if (store.getters.usrToken) {
          return next()
        }
        next('/')
      }
    },
    {
      path: '/manage/users/:uid',
      name: 'manageUsersDetails',
      props: (route) => ({
        query: route.query,
        uid: route.params.uid
      }),
      component: () => import(/* webpackChunkName: "manage" */ './views/manage/UsersDetails.vue'),
      meta: {},
      beforeEnter: (to, from, next) => {
        if (store.getters.usrToken) {
          return next()
        }
        next('/')
      }
    },
    /**
     * Feature Management
     */
    {
      path: '/manage/features',
      name: 'manageFeatures',
      props: (route) => ({
        query: route.query
      }),
      component: () => import(/* webpackChunkName: "manage" */ './views/manage/Features.vue'),
      meta: {},
      beforeEnter: (to, from, next) => {
        if (store.getters.usrToken) {
          return next()
        }
        next('/')
      }
    },
    {
      path: '/manage/features/history',
      name: 'manageFeaturesHistory',
      props: (route) => ({
        query: route.query
      }),
      component: () => import(/* webpackChunkName: "manage" */ './views/manage/FeaturesHistory.vue'),
      meta: {},
      beforeEnter: (to, from, next) => {
        if (store.getters.usrToken) {
          return next()
        }
        next('/')
      }
    },
    /**
     * Roles Management
     */
    {
      path: '/manage/roles',
      name: 'manageRoles',
      props: (route) => ({
        query: route.query
      }),
      component: () => import(/* webpackChunkName: "manage" */ './views/manage/Roles.vue'),
      meta: {},
      beforeEnter: (to, from, next) => {
        if (store.getters.usrToken) {
          return next()
        }
        next('/')
      }
    },

    /**
     * Customer Sections
     */
    {
      path: '/manage/customers',
      name: 'manageCustomers',
      props: (route) => ({
        query: route.query
      }),
      component: () => import(/* webpackChunkName: "manage" */ './views/manage/Customers.vue'),
      meta: {},
      beforeEnter: (to, from, next) => {
        if (store.getters.usrToken) {
          return next()
        }
        next('/')
      }
    },
    {
      path: '/manage/customers/:uid',
      name: 'manageCustomerDetails',
      props: (route) => ({
        uid: route.params.uid
      }),
      component: () => import(/* webpackChunkName: "manage" */ './views/manage/CustomerDetails.vue'),
      meta: {},
      beforeEnter: (to, from, next) => {
        if (store.getters.usrToken) {
          return next()
        }
        next('/')
      }
    },
    /**
     * User sections
     */
    {
      path: '/users/login',
      name: 'login',
      props: (route) => ({
        query: route.query,
        params: route.params
      }),
      component: () => import(/* webpackChunkName: "users" */ './views/users/Login.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      },
      beforeEnter: (_to, _from, next) => {
        if (store.getters.usrToken) {
          return next({ name: 'manageApplications' })
        }
        next()
      }
    },
    {
      path: '/users/forgot-password',
      name: 'forgotPassword',
      props: (route) => ({
        query: route.query,
        params: route.params
      }),
      component: () => import(/* webpackChunkName: "users" */ './views/users/ForgotPassword.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      },
      beforeEnter: (_to, _from, next) => {
        if (store.getters.usrToken) {
          return next({ name: 'manageApplications' })
        }
        next()
      }
    },
    {
      path: '/users/reset-password',
      name: 'resetPassword',
      props: (route) => ({
        query: route.query,
        params: route.params
      }),
      component: () => import(/* webpackChunkName: "users" */ './views/users/ResetPassword.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      },
      beforeEnter: (_to, _from, next) => {
        if (store.getters.usrToken) {
          return next({ name: 'manageApplications' })
        }
        next()
      }
    },
    {
      path: '/users/new',
      name: 'newUser',
      props: (route) => ({
        action: route.query.action,
        token: route.query.actionToken
      }),
      component: () => import(/* webpackChunkName: "users" */ './views/users/New.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      },
      beforeEnter: (_to, _from, next) => {
        if (store.getters.usrToken) {
          return next({ name: 'manageApplications' })
        }
        next()
      }
    },
    {
      path: '/users/new-password',
      name: 'newPassword',
      props: (route) => ({
        query: route.query,
        token: route.query.actionToken,
        action: route.query.action
      }),
      component: () => import(/* webpackChunkName: "users" */ './views/users/NewPassword.vue'),
      meta: {
        scrollToPos: { x: 0, y: 0 }
      },
      beforeEnter: (_to, _from, next) => {
        if (store.getters.usrToken) {
          return next({ name: 'manageApplications' })
        }
        next()
      }
    },
    {
      path: '/pci-success',
      name: 'pci-success',
      component: () => import(/* webpackChunkName: "pci" */ './views/pci/PCISuccess.vue')
    },
    {
      path: '/pci-fail',
      name: 'pci-fail',
      component: () => import(/* webpackChunkName: "pci" */ './views/pci/PCIFail.vue')
    }
  ]
})
router.beforeEach((_to, from, next) => {
  next()
})
router.afterEach((_to, from) => {
  let loadingInstance = Loading.service({ fullscreen: true })
  loadingInstance.close()
  store.commit('SET_PREVIOUS_ROUTE', from)
})

export default router
