import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

import ElementUI from 'element-ui'
import Pp from 'pragmatic-pack'
import VueLazyload from 'vue-lazyload'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import 'pragmatic-pack/src/packages/theme-default/src/index.scss'
import './styles/element-ui/index.scss'
import './styles/bulma.scss'
import './styles/custom.scss'

import TheHeader from '@/components/TheHeader'
import HistoryLink from '@/components/HistoryLink'
import Essentials from '@/plugins/Essentials'

Vue.config.productionTip = false

Vue.use(VueLazyload, {
  preLoad: 1.6,
  throttleWait: 300,
  attempt: 0,
  listenEvents: ['scroll']
})

/**
 * Pre-load prfs and login status
 */
try {
  store.commit('SET_META', {
    ...JSON.parse(localStorage.getItem(process.env.VUE_APP_ACCESS_KEY))
  })
} catch (err) {
  // Incase the localstorage is poluted, we will reset it
  localStorage.setItem(process.env.VUE_APP_ACCESS_KEY, null)
  store.commit('SET_META', {
    ...JSON.parse(localStorage.getItem(process.env.VUE_APP_ACCESS_KEY))
  })
}

Vue.use(Essentials)
Vue.use(Pp)
Vue.use(ElementUI)
Vue.component('TheHeader', TheHeader)
Vue.component('HistoryLink', HistoryLink)

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.Vue({ Vue, attachProps: true, logError: true })],
  environment: process.env.VUE_APP_SENTRY_ENV,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'Network Error',
    'ResizeObserver loop completed with undelivered notifications',
    /^Redirected when going from/,
    /^Non-Error promise rejection captured/,
    /^Missing required param/,
    /^Request failed with status code/
  ]
})
Sentry.configureScope((scope) => {
  scope.setTags({
    appCode: 'Admin Portal',
    brand: 'Admin Portal'
  })
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
