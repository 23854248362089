import { register } from 'register-service-worker'
import { Notification } from 'element-ui'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updated () {
      console.log('New content is available; please refresh.')
      Notification({
        dangerouslyUseHTMLString: true,
        duration: 0,
        type: 'warning',
        title: 'New version is available',
        message: '<p class="has-text-grey">Please reload</p><div style="margin-top: 12px;"><button class="el-button el-button--success el-button--small">RELOAD</button></div>',
        onClick: function () {
          window.location.reload()
        }
      })
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
