<template>
  <span :class="css">{{ label }}</span>
</template>

<script>
export default {
  name: 'InactiveFlag',
  props: {
    inactive: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  computed: {
    css () {
      return this.inactive ? 'is-inactive' : 'is-active'
    },
    label () {
      return this.inactive ? 'SUSPENDED' : 'ACTIVE'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .is-inactive {
    color: $grey-light;
    white-space: nowrap;
  }
  .is-active {
    color: $success;
    white-space: nowrap;
    font-weight: 600;
  }
</style>
