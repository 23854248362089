export function date (val, options = {
  // timeZone: true, // Subtible to pass checkin date in local browser.
  hour12: false,
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
}) {
  const locale = window.navigator.language || 'en-US'
  if (!val) return '–'
  // const { timeZone, ...rOptions } = options
  // Always set timeZone to true unless present in option false
  // const useTimeZone = timeZone === undefined ? true : timeZone
  const d = new Date(val)
  // if (useTimeZone) {
  // } else {
  //   const _d = val.match(/^(\d{4})-(\d{2})-(\d{2})/g)
  //   d = new Date(_d[0])
  //   // remove timezone
  // }
  // Something worng retrun N/A
  if (!(d.toJSON())) {
    console.error(new TypeError('val must be instance of Date'))
    return 'N.A'
  }
  const intl = new Intl.DateTimeFormat(locale, { ...options, hour12: false })
  return intl.format(d)
}

export function countDays (starDate, options = {
  endDate: new Date(),
  round: 'down'
}) {
  if (!starDate) return '–'
  if (!options.endDate) return 'N.A'
  const totalMsPerDay = 24 * 60 * 60 * 1000
  const s = new Date(starDate)
  const e = new Date(options.endDate)
  const dayDiff = Math.abs((e - s) / totalMsPerDay)
  switch (options.round) {
    case 'down':
      return Math.floor(dayDiff)
    case 'up':
      return Math.ceil(dayDiff)
    default:
      return dayDiff
  }
}

export function foodCode (val) {
  if (!val) return 'N.A'
  const k = {
    1: 'Room Only',
    2: 'Free breakfast',
    3: 'Lunch included',
    4: 'Dinner included',
    5: '2 meals (e.g. breakfast and lunch, lunch and dinner)',
    6: 'Breakfast, lunch and dinner',
    7: 'All inclusive'
  }
  return k[val]
}

/**
 * @param objVal.currency String - ISO currency code
 * @param objVal.value Number - Number to be parse
 */
export function ccyObject (objVal = {}, options = {
  factionDigits: 2,
  currencyDisplay: 'symbol'
}) {
  let { currency, ccy, value, val } = objVal
  currency = currency || ccy
  value = value || val
  if (value == null || !currency) return 'N.A'
  if (value == null) return '–'
  if (isNaN(value)) return 'N.A'
  const { factionDigits } = options
  const locale = window.navigator.language || 'en-US'
  const intl = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: options.currencyDisplay,
    maximumFractionDigits: factionDigits,
    minimumFractionDigits: factionDigits
  })
  return intl.format(value)
}

export function pct (value, options = {
  factionDigits: 2,
  minimumFractionDigits: 2
}) {
  const { factionDigits, minimumFractionDigits } = options
  const locale = window.navigator.language || 'en-US'
  if (value == null) return '–'
  if (isNaN(value)) return 'N.A'
  const intl = new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: factionDigits,
    minimumFractionDigits: minimumFractionDigits != null ? minimumFractionDigits : factionDigits
  })
  return intl.format(value)
}

export default {
  ccyObject,
  countDays,
  date,
  foodCode,
  pct
}
