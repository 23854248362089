<template>
  <div>
    <a v-if="historyBack && history.length > 1"
      @click.prevent.stop="handleHistoryBack">
      <slot>{{ label }}</slot>
    </a>
    <router-link v-else-if="route" :to="route" :replace="replace" >
      <slot>{{ label }}</slot>
    </router-link>
    <span v-else>
      <slot>{{ label }}</slot>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
/**
 * HistoryLink
 * @description navigation link to give history back priority, it's very useful when opening link in new tab, u want user able to navigate to a link.
 */
export default {
  name: 'HistoryLink',
  props: {
    historyBack: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true,
      default: ''
    },
    route: {
      type: Object,
      default () {
        return {}
      },
      required: true
    },
    replace: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // If props.route did contain 'name' we will resolve it
      resolvedRoute: {}
    }
  },
  computed: {
    history () {
      return window.history
    },
    routeName () {
      return this.route.name || this.resolvedRoute.name
    },
    ...mapState(['previousRoute'])
  },
  created () {
    if (this.route.name == null) {
      const { route } = this.$router.resolve(this.route.path)
      this.resolvedRoute = route
    }
  },
  methods: {
    handleHistoryBack () {
      // Give history priority
      if (this.previousRoute.name) {
        return this.$router.go(-1)
      }
      return this.replace ? this.$router.replace(this.route) : this.$router.push(this.route)
    }
  }
}
</script>
