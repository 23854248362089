import { mapActions } from 'vuex'
// import uuidv4 from 'uuid/v4'
// import vueSmoothScroll from 'vue2-smooth-scroll'
// import ZuDivider from '@/components/ZuDivider'
// import IconBase from '@/components/IconBase'
// import EmptyState from '@/components/EmptyState'
import PropertiesSearch from '@/components/PropertiesSearch'
import InactiveFlag from '@/components/InactiveFlag'
import BlockFlag from '@/components/BlockFlag'
import OfflineConfig from '@/components/OfflineConfig'
import store from '../store'
import filters from './filters'
import { getCountryByAlpha2 } from './countriesHelper'
// import VueSticky from 'vue-sticky'

const install = function (Vue) {
  Vue.component('PropertiesSearch', PropertiesSearch)
  Vue.component('InactiveFlag', InactiveFlag)
  Vue.component('BlockFlag', BlockFlag)
  Vue.component('OfflineConfig', OfflineConfig)
  // Vue.component('ZuDivider', ZuDivider)
  // Vue.component('IconBase', IconBase)

  // Vue.use(vueSmoothScroll)

  // Vue.prototype.$lv2_newSearchId = function () {
  //   return uuidv4()
  // }

  // Vue.prototype.$user = store.getters.user
  // Vue.prototype.$usrToken = store.getters.usrToken

  Vue.mixin({
    // data () {
    //   return {
    //     lv2State: store.state
    //   }
    // },
    // directives: {
    //   'sticky': VueSticky
    // },
    computed: {
      mx_user () {
        return store.getters.user
      },
      mx_usrToken () {
        return store.getters.usrToken
      }
    },
    methods: {
      ...mapActions({
        mx_setMeta: 'setMeta'
      })
    }
  })
  Vue.directive('opacity-zero', function (el, binding) {
    if (binding.value) {
      el.style.opacity = 0
    } else {
      el.style.opacity = 1
    }
  })

  Vue.directive('permission', function (el, binding) {
    const { value } = binding
    const rolePermissions = store.getters && store.getters.rolePermissions

    if (value && value instanceof Array) {
      if (value.length > 0) {
        const checkedPermissions = value

        const hasPermission = rolePermissions.some(role => {
          return checkedPermissions.includes(role)
        })

        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      }
    } else {
      throw new Error(`need roles! Like v-permission="['admin','editor']"`)
    }
  })

  Vue.filter('countDays', filters.countDays)

  Vue.filter('status', function (inactive) {
    return inactive ? 'INACTIVE' : 'ACTIVE'
  })

  Vue.filter('nightLabel', function (night) {
    return night > 1 ? `${night} nights` : '1 night'
  })

  Vue.filter('date', filters.date)

  Vue.filter('currency', function (value, options = {
    currency: 'USD',
    factionDigits: 2
  }) {
    const { currency, factionDigits } = options
    const locale = window.navigator.language || 'en-US'
    if (value == null) return '–'
    if (isNaN(value)) return 'N.A'
    const intl = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: factionDigits,
      minimumFractionDigits: factionDigits
    })
    return intl.format(value)
  })

  Vue.filter('taxesAndFees', function (rate, options = {
    offset: false // If True return the price minus taxesAndFees
  }) {
    const { offset } = options

    return {
      value: offset ? rate.value - rate.taxesAndFeesTotal : rate.taxesAndFeesTotal,
      currency: rate.currency,
      taxesConfident: rate.taxesConfident
    }
  })

  Vue.filter('refundablePaser', function (val) {
    return (val === undefined || val) ? '–' : 'Refundable'
  })

  Vue.filter('pct', filters.pct)

  /**
   * @param objVal.currency String - ISO currency code
   * @param objVal.value Number - Number to be parse
   */
  Vue.filter('currencyObj', filters.ccyObject)

  /**
   * @param value Number - Number to be parse
   */
  Vue.filter('number', function (value, options = {}) {
    if (value == null) return '–'
    if (isNaN(value)) return 'N.A'
    const locale = window.navigator.language || 'en-US'
    const intl = new Intl.NumberFormat(locale, {
      style: 'decimal',
      ...options
    })
    return intl.format(value)
  })

  Vue.filter('foodCode', filters.foodCode)
  Vue.filter('country', function (val) {
    if (val == null) return val
    const k = getCountryByAlpha2(val)
    if (k == null) return val
    return k.lb
  })

  Vue.filter('checkInOutTime', function (value) {
    return value ? value.split(':').slice(0, 2).join(':') : '-'
  })
}

export default {
  install
}
