import store from '@/store'

/**
 * @param {Array} value
 * @returns {Boolean}
 */
export default function checkPermission (value) {
  if (value && value instanceof Array && value.length > 0) {
    const rolePermissions = store.getters && store.getters.rolePermissions
    const checkedPermissions = value

    const hasPermission = rolePermissions.some(role => {
      return checkedPermissions.includes(role)
    })

    if (!hasPermission) {
      return false
    }
    return true
  } else {
    console.error(`need roles! Like v-permission="['admin','editor']"`)
    return false
  }
}
