<template>
  <el-popover
    placement="top"
    width="200"
    trigger="hover"
    content="">
    <div style="text-align: left;">
    <p class="title is-6 has-text-info">Manual Config</p>
    <div class="subtitle is-7 has-text-grey">
      This configurations required offline manual works, it will takes 1 ~ 2 working days.
    </div>
    </div>
    <a slot="reference" class="is-size-7 has-text-info"><i class="el-icon-warning"/> MANUAL</a>
  </el-popover>
</template>

<script>
export default {
  name: 'OfflineConfig'
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
</style>
