/* eslint-disable no-console */
import Vue from 'vue'
import Vuex from 'vuex'
import { groupBy } from 'lodash'
import checkPermission from '@/plugins/permission'

Vue.use(Vuex)

const ORDERS_COLS_KEY = 'lv2prt_okik'

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    meta: {
      usrToken: null,
      user: {
        apps: []
      }
    },
    apps: {
      list: []
    },
    clients: {
      list: []
    },
    featuresHistory: {
      list: []
    },
    features: {
      list: []
    },
    permissions: {
      list: []
    },
    appsDetails: {},
    users: {},
    usersDetails: {
      details: {
        apps: []
      }
    },
    roles: {
      list: []
    },
    orders: {
      summary: { total: undefined, showing: [] },
      rows: [],
      cols: [],
      sort: ''
    },
    affiliates: {
      isLoading: false,
      summary: { total: undefined, showing: [] },
      rows: []
    },
    ordersDetails: {
      app: {},
      order: {},
      property: {},
      booking: {},
      payment: {},
      contactPerson: {},
      history: []
    },
    previousRoute: {},
    customers: {},
    stripeClientSecret: undefined,
    stripeCreditCards: [],
    pciBookingCreditCards: [],
    pciBookingInfo: {},
    customersDetails: {
      details: {
      }
    },
    zeroDecimalCurrencies: [
      'BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF', 'UGX'
    ]
  },
  getters: {
    user: state => {
      return state.meta.user || {}
    },
    rolePermissions: state => {
      return (state.meta.user && state.meta.user.rolePermissions) || []
    },
    appFeatures: state => {
      return (state.appsDetails && state.appsDetails.details && state.appsDetails.details.features) || []
    },
    usrToken: state => {
      return state.meta.usrToken || null
    },
    appRoles: state => {
      return state.roles.list.filter(ele => ele.level <= 777)
    },
    codeToRole: state => roleCode => {
      return state.roles.list.find(ele => ele.code === roleCode) || {}
    },
    userApps: state => {
      return state.usersDetails.details.apps
    },
    isUserConfigureColumnsEnabled: (state, getters) => {
      return checkPermission(['ORDERS_CONFIGURE_TABLE'])
    },
    // TODO: not hardcode, should check the feature enabled
    mealInfoFeatureEnabled: (state, getters) => {
      return getters.user.level >= 900 || getters.user.clientCode === 'HC'
    },
    rateTypeEnabled: (state, getters) => {
      const isAppAdmin = (getters.user.level >= 900 || getters.user.clientCode === 'HC' || getters.user.clientCode === 'DR')
      // WL-1237 - added only for Fly Louie app admin
      let isFlyLouieAppAdmin = false
      if (getters.user.level < 900 && getters.user.clientCode === 'HC' && !getters.isUserConfigureColumnsEnabled) {
        const included = getters.user.apps.find((app) => app.appCode === 'HCBK')
        if (included) {
          isFlyLouieAppAdmin = true
        }
      }
      return isAppAdmin || isFlyLouieAppAdmin
    },
    featureCodes: (state, getters) => {
      return state.features.list.map(f => f.code)
    },
    featuresTableData: (state, getters) => {
      return getters.featureCodes.length ? state.apps.list.map(item => {
        item.featureCheckboxs = {}
        getters.featureCodes.forEach((code) => {
          if (item.features.indexOf(code) !== -1) {
            item.featureCheckboxs[code] = true
          } else {
            item.featureCheckboxs[code] = false
          }
        })
        item.select = Object.values(item.featureCheckboxs).every((item) => item)
        return Object.assign({}, item)
      }) : []
    },
    groupedFeatures: (state, getters) => {
      return groupBy(state.features.list, 'group')
    },
    permissionCodes: (state, getters) => {
      return state.permissions.list.map(f => f.code)
    },
    groupedPermissions: (state, getters) => {
      return groupBy(state.permissions.list, 'group')
    },
    rolesTableData: (state, getters) => {
      return getters.permissionCodes.length ? state.roles.list.map(item => {
        item.permissionCheckboxs = {}
        if (!item.permissions) {
          item.permissions = []
        }
        getters.permissionCodes.forEach((code) => {
          if (item.permissions.indexOf(code) !== -1) {
            item.permissionCheckboxs[code] = true
          } else {
            item.permissionCheckboxs[code] = false
          }
        })
        item.select = Object.values(item.permissionCheckboxs).every((item) => item)
        item.editStatus = 0
        return Object.assign({}, item)
      }) : []
    }
  },
  mutations: {
    SET_META (state, nV) {
      state.meta = nV
    },
    SET_ROLES (state, nV) {
      state.roles = { ...state.roles, ...nV }
    },
    SET_USERS (state, nV) {
      state.users = { ...state.users, ...nV }
    },
    SET_USERS_DETAILS (state, nV) {
      state.usersDetails = { ...state.usersDetails, ...nV }
    },
    SET_PREVIOUS_ROUTE (state, nV) {
      state.previousRoute = nV
    },
    SET_APPS (state, nV) {
      state.apps = nV
    },
    SET_CLIENTS (state, nV) {
      state.clients = nV
    },
    SET_APPS_DETAILS (state, nV) {
      state.appsDetails = nV
    },
    SET_AFFILIATES (state, nV) {
      // state.orders.summary = nV.summary
      state.affiliates.rows = nV.rows
    },
    SET_AFFILIATES_LOADING (state, nV) {
      state.affiliates.isLoading = nV
    },
    SET_ORDERS (state, nV) {
      state.orders.summary = nV.summary
      state.orders.rows = nV.rows
    },
    SET_ORDERS_DETAILS (state, nV) {
      state.ordersDetails.app = nV.app
      state.ordersDetails.booking = nV.booking
      state.ordersDetails.contactPerson = nV.contactPerson
      state.ordersDetails.order = nV.order
      state.ordersDetails.payment = nV.payment
      state.ordersDetails.property = nV.property
    },
    SET_ORDERS_DETAILS_HISTORY (state, nV) {
      state.ordersDetails.history = nV
    },
    SET_ORDERS_COLS (state, nV) {
      if (nV.cols != null) state.orders.cols = nV.cols
      if (nV.sort != null) state.orders.sort = nV.sort
    },
    SET_CUSTOMERS (state, nV) {
      state.customers = { ...state.customers, ...nV }
    },
    SET_CUSTOMERS_DETAILS (state, nV) {
      state.customersDetails = { ...state.customersDetails, ...nV }
    },
    SET_STRIPE_CLIENT_SECRET (state, nV) {
      state.stripeClientSecret = nV
    },
    SET_STRIPE_CREDIT_CARDS (state, nV) {
      state.stripeCreditCards = nV
    },
    SET_PCI_BOOKING_CREDIT_CARDS (state, nV) {
      state.pciBookingCreditCards = nV
    },
    SET_PCI_BOOKING_INFO (state, nV) {
      state.pciBookingInfo = nV
    },
    SET_FEATURES_HISTORY (state, nV) {
      state.featuresHistory = nV
    },
    SET_FEATURES (state, nV) {
      state.features = nV
    },
    SET_PERMISSIONS (state, nV) {
      state.permissions = nV
    }
  },
  actions: {
    setMeta ({ commit }, nV) {
      if (nV != null) {
        if (nV.hasOwnProperty('usrToken') || nV.hasOwnProperty('user')) {
          const stringify = JSON.stringify({
            ...JSON.parse(localStorage.getItem(process.env.VUE_APP_ACCESS_KEY)),
            usrToken: nV.usrToken,
            user: nV.user })
          localStorage.setItem(process.env.VUE_APP_ACCESS_KEY, stringify)
        }
        commit('SET_META', nV)
      }
    },
    setRoles ({ commit }, nV) {
      commit('SET_ROLES', { list: [], ...nV })
    },
    setUsers ({ commit }, nV) {
      commit('SET_USERS', nV)
    },
    setUsersDetails ({ commit, state }, nV) {
      commit('SET_USERS_DETAILS', nV)
    },
    setApps ({ commit, state }, nV) {
      commit('SET_APPS', nV)
    },
    setClients ({ commit, state }, nV) {
      commit('SET_CLIENTS', nV)
    },
    setAppsDetails ({ commit, state }, nV) {
      commit('SET_APPS_DETAILS', nV)
    },
    setAffiliates ({ commit, state }, nV) {
      commit('SET_AFFILIATES', nV)
    },
    setOrders ({ commit, state }, nV) {
      commit('SET_ORDERS', nV)
    },
    loadOrdersCols ({ commit, state }) {
      let defaultCols = ['requestedAt', 'appLabel', 'orderReference', 'orderStatus', 'stayPeriods', 'roomDescription', 'primaryGuest', 'contactPerson']
      // TODO: dont hardcode
      const mealInfoFeatureEnabled = state.meta.user && state.meta.user.clientCode === 'HC'
      if (mealInfoFeatureEnabled) {
        defaultCols = ['requestedAt', 'appLabel', 'orderReference', 'orderStatus', 'mealVoucher', 'stayPeriods', 'roomDescription', 'primaryGuest', 'contactPerson']
      }
      // TODO: dont hardcode
      const rateTypeEnabled = state.meta.user && (state.meta.user.level >= 900 || state.meta.user.clientCode === 'HC' || state.meta.user.clientCode === 'DR')
      if (rateTypeEnabled) {
        defaultCols.splice(4, 0, 'rateType')
      }
      let k
      try {
        k = JSON.parse(localStorage.getItem(ORDERS_COLS_KEY))
      } catch (err) {
        console.error('Invalid saved cols, reset to default cols')
      }

      if (k == null || (Array.isArray(k) && k.length === 0)) {
        k = defaultCols
        localStorage.setItem(ORDERS_COLS_KEY, JSON.stringify(k))
      }

      commit('SET_ORDERS_COLS', { cols: [...k] })
    },
    setOrdersCols ({ commit, state }, nV) {
      if (nV.cols != null) localStorage.setItem(ORDERS_COLS_KEY, JSON.stringify(nV.cols))
      commit('SET_ORDERS_COLS', nV)
    },
    setOrdersDetails ({ commit, state }, nV) {
      commit('SET_ORDERS_DETAILS', nV)
    },
    setCustomers ({ commit }, nV) {
      commit('SET_CUSTOMERS', nV)
    },
    setCustomersDetails ({ commit, state }, nV) {
      commit('SET_CUSTOMERS_DETAILS', nV)
    },
    setOrdersDetailsHistory ({ commit, state }, nV) {
      commit('SET_ORDERS_DETAILS_HISTORY', nV)
    }
  }
})
