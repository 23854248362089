<template>
  <header class="main-header">
    <div :class="layout">
  <nav class="nav-main" role="navigation" aria-label="main navigation">
    <div class="nav-left">
      <router-link
        v-if="mx_usrToken && checkPermission(['APPLICATION_LIST'])"
        class="nav-item nav-link has-text-link" :to="{ name: 'manageApplications' }">
        Applications
      </router-link>
      <router-link
        v-if="mx_usrToken && checkPermission(['ORDERS_VIEW'])"
        class="nav-item nav-link has-text-link" :to="{ name: 'orders' }">
        Orders
      </router-link>
      <router-link
        v-if="mx_usrToken && checkPermission(['USERS_VIEW', 'USERS_EDIT'])"
        class="nav-item nav-link has-text-link" :to="{ name: 'manageUsers' }">
        Users
      </router-link>
      <router-link
        v-if="mx_usrToken && checkPermission(['ROLES_MANAGE'])"
        class="nav-item nav-link has-text-link" :to="{ name: 'manageRoles' }">
        Roles & Permissions
      </router-link>
      <router-link
        v-if="mx_usrToken && checkPermission(['VIEW_CUSTOMERS'])"
        class="nav-item nav-link has-text-link" :to="{ name: 'manageCustomers' }">
        Customers
      </router-link>
      <router-link
        v-if="mx_usrToken && checkPermission(['FEATURES_MANAGE'])"
        class="nav-item nav-link has-text-link" :to="{ name: 'manageFeatures' }">
        Features
      </router-link>
    </div>
    <div class="nav-right"
      v-cloak>
      <a class="nav-item nav-link"
        v-if="mx_usrToken">
        <span>{{ mx_user.displayName }}</span> &nbsp;|&nbsp; <span class="has-text-grey">{{ mx_user.roleName }}</span>
      </a>
      <a class="nav-item nav-link has-text-link"
        v-if="mx_usrToken"
        @click.prevent="handleLogout">Logout
      </a>
      <router-link class="nav-item nav-link has-text-link"
        :to="{ name: 'login', query: { reff } }"
        v-if="!mx_usrToken">
        Login
      </router-link>
    </div>
  </nav>
    </div>
  </header>
</template>

<script>
import checkPermission from '@/plugins/permission'

export default {
  props: {
    routeMeta: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      adminMenus: [
        { label: 'Applications Management', to: { name: 'manageApplications' } },
        { label: 'Users Management', to: { name: 'manageUsers' } },
        { label: 'Customers Management', to: { name: 'manageCustomers' } },
        { label: 'Features Management', to: { name: 'manageFeatures' } },
        { label: 'Roles Management', to: { name: 'manageRoles' } }
      ],
      currencyModal: false
    }
  },
  filters: {
    roleTypeName (val) {
      const types = [
        {
          label: 'Internal',
          value: 'INTERNAL'
        },
        {
          label: 'Client',
          value: 'CLIENT'
        },
        {
          label: 'Application',
          value: 'APPLICATION'
        }
      ]
      const f = types.find(e => e.value === val)
      return f && f.label
    }
  },
  computed: {
    showLoginLink () {
      return !this.mx_isLoggedIn && !['login', 'signup', 'auth'].includes(this.$route.name)
    },
    reff () {
      return btoa(this.$route.fullPath)
    },
    layout () {
      return this.routeMeta.hasHeaderFullWidth ? '' : ''
    },
    selectedCurrency: {
      get () {
        return this.$route.query.currency || process.env.VUE_APP_DEFAULT_CURRENCY
      },
      set (ccy) {
        this.$lv2Store.setMetaAction({ ...this.lv2State.meta, currency: ccy })
      }
    }
  },
  methods: {
    checkPermission,
    toggleCurrencyModal () {
      this.currencyModal = !this.currencyModal
    },
    currencyChanged (ccy) {
      this.toggleCurrencyModal()
      // EventBus.$emit('currency-changed', { currency: ccy })
    },
    handleLogout () {
      this.mx_setMeta({ user: null, usrToken: null })
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .main-header {
    box-shadow: $list-border-shadow;
    background-color: $lw-header-backgroud-color;
  }
  @include tablet {
    .container.is-desktop {
      .nav-logo {
        margin-left: -$bleed * 2;
      }
    }
  }
  .main-logo {
    height: 32px;
  }
</style>
