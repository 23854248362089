import lv2, {
  CancelToken,
  axiosErrorHandler
} from './lv2Api'

import {
  Message
} from 'element-ui'
import store from '../store'

export const source = {
  usersList: CancelToken.source()
}

const showErrMessage = (message = '') => {
  Message({
    showClose: true,
    duration: 5000,
    dangerouslyUseHTMLString: true,
    message: `<div>Oops, ${message}</div>`,
    type: 'error'
  })
}

export const usersList = (query) => {
  store.dispatch('setUsers', {
    isSearching: true
  })

  return lv2.get('/users/list', {
    params: query
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    store.dispatch('setUsers', {
      isSearching: false,
      ...outlets.list
    })
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    store.dispatch('setUsers', {
      isSearching: false
    })
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const usersOne = (query) => {
  return lv2.get('/users/one', {
    params: query
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    store.dispatch('setUsersDetails', {
      ...outlets
    })
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const usersAutosuggest = (query) => {
  return lv2.get('/users/autosuggest', {
    params: query
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const usersEdit = (body) => {
  return lv2.put('/users/edit', body, {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      Message({
        message: 'Saved!',
        type: 'success'
      })
      return outlets
    }).catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      const errMsg = (parseErr.error && parseErr.error.message) || parseErr.message
      showErrMessage(errMsg)
      throw parseErr.error
    })
}

export const usersInvite = (body) => {
  return lv2.post('/users/invite', body, {
    cancelToken: source.usersList.token
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    Message({
      message: 'Invited!',
      type: 'success'
    })
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const sendInvitationEmail = (body) => {
  return lv2.post('/users/resendInvitationEmail', body, {
    cancelToken: source.usersList.token
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    Message({
      message: 'Invitation email has been sent',
      type: 'success'
    })
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const usersNew = (body) => {
  return lv2.post('/users/new', body, {
    cancelToken: source.usersList.token
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    Message({
      message: 'Created!',
      type: 'success'
    })
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const rolesList = () => {
  store.dispatch('setRoles', {
    isSearching: true
  })

  return lv2.get('/roles/list', {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      store.dispatch('setRoles', {
        isSearching: false,
        list: outlets.list.rows
      })
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      store.dispatch('setRoles', {
        isSearching: false
      })
      showErrMessage(parseErr.message)
    })
}

export const applicationsNew = (body) => {
  return lv2.post('/applications/new', body, {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      Message({
        message: 'Created!',
        type: 'success'
      })
      return outlets
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}

export const applicationsEditUsers = (body) => {
  return lv2.put('/applications/edit/users', body, {})
    .then(({
      data
    }) => {
      const {
        meta
      } = data
      store.dispatch('setMeta', meta)
      Message({
        message: 'Saved!',
        type: 'success'
      })
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}
export const applicationsEditInvite = (body) => {
  return lv2.put('/applications/edit/invite', body, {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      return outlets
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}

export const applicationsEditConfigurations = (body) => {
  return lv2.put('/applications/edit/configurations', body, {})
    .then(({
      data
    }) => {
      const {
        meta
      } = data
      store.dispatch('setMeta', meta)
      Message({
        message: 'Saved!',
        type: 'success'
      })
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}
export const applicationsPublishPricings = (body) => {
  return lv2.put('/applications/publish/pricings', body, {})
    .then(({
      data
    }) => {
      const {
        meta
      } = data
      store.dispatch('setMeta', meta)
      Message({
        message: 'Publish!',
        type: 'success'
      })
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}

export const applicationsEdit = (body) => {
  return lv2.put('/applications/edit', body, {})
    .then(({
      data
    }) => {
      const {
        meta
      } = data
      store.dispatch('setMeta', meta)
      Message({
        message: 'Save!',
        type: 'success'
      })
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}

export const applicationsList = () => {
  return lv2.get('/applications/list', {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      store.dispatch('setApps', {
        ...outlets
      })
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}

export const applicationsOne = (query) => {
  return lv2.get('/applications/one', {
    params: query
  }).then(({ data }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    store.dispatch('setAppsDetails', {
      ...outlets
    })
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const applicationsDev = (query) => {
  return lv2.get('/applications/dev', {
    params: query
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const pricingsPreview = (body) => {
  return lv2.post('/pricings/preview', body, {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      return outlets
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}

export const pricingsSave = (body) => {
  return lv2.put('/pricings/save', body, {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      return outlets
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}

export const affiliatesInvite = (body) => {
  store.commit('SET_AFFILIATES_LOADING', true)
  return lv2.post('/affiliate/invite', body, {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      Message({
        message: 'Invited!',
        type: 'success'
      })
      store.commit('SET_AFFILIATES_LOADING', false)
      return outlets
    })
    .catch((err) => {
      store.commit('SET_AFFILIATES_LOADING', false)
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}

export const affiliatesEdit = (body) => {
  return lv2.put('/affiliate/edit', body, {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      Message({
        message: 'Updated!',
        type: 'success'
      })
      return outlets
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}

export const affiliatesList = (query) => {
  return lv2.get('/affiliate/list', {
    params: {
      ...query
    }
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    store.dispatch('setAffiliates', outlets.list)
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const ordersList = (query, cols = []) => {
  return lv2.get('/orders/list', {
    params: {
      ...query,
      cols: cols.join(',')
    }
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    store.dispatch('setOrders', outlets.list)
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const ordersOne = (query) => {
  return lv2.get('/orders/one', {
    params: {
      ...query
    }
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    store.dispatch('setOrdersDetails', outlets.details)
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const ordersOneHistory = (query) => {
  return lv2.get(`/orders/one/${query.orderReference}/history?clientCode=${query.clientCode}&appCode=${query.appCode}`).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    store.dispatch('setOrdersDetailsHistory', outlets.details.history)
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const ordersXls = (query, cols = [], limit) => {
  return lv2.post('/orders/xls', {}, {
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    params: {
      ...query,
      limit,
      cols: cols.join(',')
    },
    responseType: 'blob'
  }).then(({
    data,
    headers
  }) => {
    const contentDisposition = headers['content-disposition']
    const filename = contentDisposition.replace('attachment; filename=', '')
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    const url = window.URL.createObjectURL(data)
    a.href = url
    a.download = filename
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}
/**
 * Zumata hotel api
 */
export const zumataAutosuggest = (query, appCode) => {
  return lv2.get('/zumata/autosuggest', {
    params: {
      ...query,
      appCode
    }
  }).then(({
    data
  }) => {
    const {
      outlets = {}, meta
    } = data
    store.dispatch('setMeta', meta)
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}
/**
 * List of Customers
 * @param {*} query {appCode, order}
 */
export const customersList = (query) => {
  store.dispatch('setCustomers', {
    isSearching: true
  })

  return lv2.get('/customers/list', {
    params: query
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    store.dispatch('setCustomers', {
      isSearching: false,
      ...outlets.list
    })
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    store.dispatch('setCustomers', {
      isSearching: false
    })
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const customersOne = (query) => {
  return lv2.get('/customers/one', {
    params: query
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    store.dispatch('setCustomersDetails', {
      ...outlets
    })
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const customersEdit = (body) => {
  return lv2.put('/customers/edit', body, {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      Message({
        message: 'Saved!',
        type: 'success'
      })
      // store.dispatch('setUsersDetails', { ...outlets })
      return outlets
    }).catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}

export const customerSendInvitationEmail = (body) => {
  return lv2.post('/customers/resendInvitationEmail', body, {
    cancelToken: source.usersList.token
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    Message({
      message: 'Invitation email has been sent',
      type: 'success'
    })
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const customersInvite = (body) => {
  return lv2.post('/customers/invite', body, {
    cancelToken: source.usersList.token
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    Message({
      message: 'Invited!',
      type: 'success'
    })
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const editTemplate = (body) => {
  return lv2.put('/templates/edit', body, {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      return outlets
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}

export const previewTemplate = (body) => {
  return lv2.post('/templates/preview', body, {})
    .then(({
      data
    }) => {
      return data
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}
/**
 * update features
 * @param body
 * @returns {Promise<AxiosResponse<any>>}
 */

export const updateFeatures = (body) => {
  return lv2.post('/applications/features', body, {}).then(({ data }) => data).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    showErrMessage(parseErr.message)
    store.dispatch('setMeta', parseErr.meta)
    throw parseErr
  })
}

export const featuresHistoryList = () => {
  // FIXME, remove later
  return new Promise((resolve, reject) => {
    let mock = {
      list: [
        {
          createdAt: '2020-1-1',
          type: 'ADDED_USER',
          req: {
            id: 'XXX',
            item: 'XXX'
          },
          by: 'Sam'
        }
      ]
    }
    store.commit('SET_FEATURES_HISTORY', {
      ...mock
    })
    resolve(mock)
  })
}

export const getFeatures = () => {
  return lv2.get('/features', {}).then(({ data }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    store.commit('SET_FEATURES', {
      ...outlets
    })
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}

export const getPermissions = () => {
  return lv2.get('/roles/permissions', {}).then(({ data }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    store.commit('SET_PERMISSIONS', {
      ...outlets
    })
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    showErrMessage(parseErr.message)
    throw parseErr
  })
}
/**
 * update roles
 * @param body
 * @returns {Promise<AxiosResponse<any>>}
 */

export const updateRolesPermissions = (body) => {
  return lv2.put('/roles/permissions', body, {}).then(({ data }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    showErrMessage(parseErr.message)
    store.dispatch('setMeta', parseErr.meta)
    throw parseErr
  })
}

/**
 * create new role
 * @param body
 * @returns {Promise<AxiosResponse<any>>}
 */

export const createRole = (body) => {
  return lv2.post('/roles/new', body, {}).then(({ data }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    showErrMessage(parseErr.message)
    store.dispatch('setMeta', parseErr.meta)
    throw parseErr
  })
}

/**
 * update role
 * @param body
 * @returns {Promise<AxiosResponse<any>>}
 */

export const updateRole = (body) => {
  return lv2.put('/roles/update', body, {}).then(({ data }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    showErrMessage(parseErr.message)
    store.dispatch('setMeta', parseErr.meta)
    throw parseErr
  })
}

export const clientList = () => {
  return lv2.get('/clients/list', {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      store.dispatch('setClients', {
        ...outlets
      })
    })
    .catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      showErrMessage(parseErr.message)
      throw parseErr
    })
}

export const checkMailStatus = (query) => {
  return lv2.get('/mail/status', {
    params: query
  }).then(({
    data
  }) => {
    const {
      outlets,
      meta
    } = data
    store.dispatch('setMeta', meta)
    return outlets
  }).catch((err) => {
    const parseErr = axiosErrorHandler(err)
    store.dispatch('setMeta', parseErr.meta)
    throw parseErr
  })
}

export const updateOrderStatusLog = (body) => {
  return lv2.put('/mail/updateOrderStatusLog', body, {})
    .then(({
      data
    }) => {
      const {
        outlets,
        meta
      } = data
      store.dispatch('setMeta', meta)
      return outlets
    }).catch((err) => {
      const parseErr = axiosErrorHandler(err)
      store.dispatch('setMeta', parseErr.meta)
      throw parseErr
    })
}
